<template>
  <div>
    <v-card class="mx-4">
      <v-list-item three-line>
        <v-list-item-avatar tile size="80" color="grey">
          <v-img max-height="80" max-width="80" :src="product.product_fields.thumbnail"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <div class="mb-1 text--secondary">
            {{ product.product_fields.title }}
          </div>
          <div>
            <span v-for="{ choice_name } in product.sku_fields" v-bind:key="choice_name">
              <v-chip v-if="!!choice_name" class="mr-2" x-small label>
                {{ choice_name }}
              </v-chip>
            </span>
          </div>
          <div v-if="product.is_onsale" class="mt-1">
            <span class="font-weight-bold text-decoration-line-through">
              ¥{{ product.sku_price }}
            </span>
            <span class="font-weight-bold">&nbsp;¥{{ product.sku_sale_price }}</span>
            <span class="text-caption text--disabled">
              ¥{{ Math.floor(product.sku_sale_price * (1 + product.product_fields.tax)) }}(税込)
            </span>
          </div>
          <div v-else class="mt-1">
            <span class="font-weight-bold">¥{{ product.sku_price }}</span>
            <span class="text-caption text--disabled">
              ¥{{ Math.floor(product.sku_price * (1 + product.product_fields.tax)) }}(税込)
            </span>
          </div>
          <v-row no-gutters justify="end" align="end">
            <v-btn
              icon
              color="light-blue"
              small
              @click="() => handleMinus(product.sku_id)"
              :disabled="!product.sku_number"
            >
              <v-icon>mdi-minus-circle-outline</v-icon>
            </v-btn>
            <div class="mx-2 text-h6">
              {{ product.sku_number }}
            </div>
            <v-btn icon color="light-blue" small @click="() => handlePlus(product.sku_id)">
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'RecoseleUserCartItems',

    data() {
      return {};
    },

    props: {
      product: Object,
      handlePlus: Function,
      handleMinus: Function,
    },

    mounted() {},

    methods: {},
  };
</script>

<style lang="scss" scoped></style>
